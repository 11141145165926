<template>
  <div class="col-md-12">
    <h2><slot name="title"></slot></h2>

    <div class="unavailable-box" v-if="items.length === 0">
      No recent items available
    </div>

    <div
      class="block tabular payment"
      v-bind:key="item.id"
      v-for="item in items.slice(0, toShow)"
    >
      <div class="row">
        <div class="col-1">
          <span class="icon" style="position:absolute; left: 0px;">
            <font-awesome-icon icon="file-alt" />
          </span>
        </div>
        <div class="col-7">
          <div class="head">{{ itemHeading(item) }}</div>
          <div class="sub" v-if="item.invoice_number">
            {{ item.invoice_number }}
          </div>
          <div class="sub" v-if="item.generated_at">
            Invoice Date: {{ item.generated_at }}
          </div>
          <div class="sub" v-if="item.credited_at">
            Credit Date: {{ item.credited_at }}
          </div>
          <div class="sub" v-if="item.account">Account: {{ item.account }}</div>
          <div class="sub" v-if="item.is_sub_account">
            This relates to a sub account
          </div>
        </div>
        <div class="col-3 col-sm-4">
          <div style="text-align: right;">
            <div class="price">&pound;{{ prefix }}{{ item.bill_total }}</div>
            <div style="margin: 10px 0px;">
              <b-button
                v-if="item.is_file"
                :disabled="disableDownloads"
                @click="downloadItem(item)"
                class="sm-btn"
                size="sm"
                variant="outline-info"
                >Download</b-button
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-button
      v-if="showLoadMore"
      @click="loadMore"
      size="sm"
      class="sm-btn"
      variant="outline-info"
      >Load More</b-button
    >
  </div>
</template>

<script>
import { billDownload } from "@/components/mixins/bill-download";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

export default {
  name: "CurrentBillsList",
  props: {
    dashboardReference: {
      type: String,
      required: true
    },
    items: {
      type: Array,
      required: true
    },
    isCredit: Boolean,
    isChase: Boolean
  },
  mixins: [billDownload],
  components: {
    FontAwesomeIcon
  },
  data() {
    return {
      toShow: 5
    };
  },
  computed: {
    showLoadMore() {
      return this.toShow < this.items.length;
    },
    prefix() {
      return this.isCredit ? "-" : "";
    }
  },
  methods: {
    loadMore() {
      this.toShow += 5;
    },
    itemHeading(item) {
      if (item.ends) {
        return item.starts + " - " + item.ends;
      }
      return item.starts + " (" + item.phase + ")";
    },
    downloadItem(item) {
      this.downloadBill(
        item.id,
        item.end_file,
        this.dashboardReference,
        this.isCredit,
        this.isChase
      );
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../../scss/dashboard.scss";

.sm-btn {
  font-size: 0.7rem;
  line-height: 1.3;
}
</style>
