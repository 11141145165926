import axios from "axios";

export const billDownload = {
  data() {
    return {
      disableDownloads: false
    };
  },
  methods: {
    downloadBill(
      billReference,
      date,
      reference,
      isCredit = false,
      isChasing = false,
      isAttachment = false,
      fileName = ""
    ) {
      let pathPrefix = "bill";
      if (isCredit) {
        pathPrefix = "credit";
      } else if (isChasing) {
        pathPrefix = "chase";
      } else if (isAttachment) {
        pathPrefix = "attachment";
      }

      this.disableDownloads = true;
      axios({
        url:
          process.env.VUE_APP_API_URL +
          "dashboard/billing/" +
          reference +
          "/" +
          pathPrefix +
          "/" +
          billReference,
        method: "GET",
        responseType: "blob"
      })
        .then(response => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement("a");

          let filePath = pathPrefix + "_" + date + ".pdf";

          if (isAttachment) {
            filePath = fileName;
          }

          fileLink.href = fileURL;
          fileLink.setAttribute("download", filePath);

          document.body.appendChild(fileLink);

          fileLink.click();
        })
        .finally(() => {
          this.disableDownloads = false;
        });
    }
  }
};
